"use client";
import React, { useRef, useState } from "react";
import BadgeAnimatedGradientBorder from "./BadgeAnimatedGradienteBorder";

const CardSpotlight = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [opacity, setOpacity] = useState(0);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!divRef.current || isFocused) return;

    const div = divRef.current;
    const rect = div.getBoundingClientRect();

    setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  const handleFocus = () => {
    setIsFocused(true);
    setOpacity(1);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setOpacity(0);
  };

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  return (
    <div
      ref={divRef}
      onMouseMove={handleMouseMove}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="relative flex w-[90%] md:w-[70%] h-[50%] items-center justify-center overflow-hidden rounded-xl border border-gray-800 bg-gradient-to-r from-black to-gray-950 px-4 md:px-8 py-8 md:py-16 shadow-2xl"
    >
      <div
        className="pointer-events-none absolute -inset-px opacity-0 transition duration-300"
        style={{
          opacity,
          background: `radial-gradient(600px circle at ${position.x}px ${position.y}px, rgba(255,182,255,.1), transparent 40%)`,
        }}
      />

      <div className="relative z-10 text-white text-center">
        <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-8">Meanwhile, We're here to help! Contact us via email.</h2>
        <div className="flex flex-col items-center justify-center">
          <BadgeAnimatedGradientBorder text="info@cybisolutions.com" email="info@cybisolutions.com" />
        </div>
      </div>
    </div>
  );
};

export default CardSpotlight;
