import React from "react";
import CardSpotlight from "../../components/Card";

const UnderConstructionPage: React.FC = () => {
  return (
    <div className="absolute top-0 z-[-2] h-screen w-screen bg-neutral-950 bg-[radial-gradient(ellipse_80%_80%_at_50%_-20%,rgba(120,119,198,0.3),rgba(255,255,255,0))]">
      <div className="flex flex-col h-full items-center justify-center space-y-5 px-4 md:px-8 lg:px-16">
        <h1 className="inline-flex animate-background-shine bg-[linear-gradient(110deg,#fff,45%,#939393,55%,#fff)] bg-[length:250%_100%] bg-clip-text text-2xl md:text-4xl font-bold text-transparent py-2 text-center md:text-left">
          Cybi Solutions is building something great!
        </h1>
        <p className="inline-flex bg-[linear-gradient(110deg,#fff,45%,#939393,55%,#fff)] bg-[length:250%_100%] bg-clip-text text-lg md:text-2xl font-bold text-transparent py-2 text-center md:text-left text-justify">
          Our site is currently under construction.
        </p>
        <CardSpotlight />
      </div>
    </div>
  );
};

export default UnderConstructionPage;
