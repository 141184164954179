import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import UnderConstructionPage from "../pages/UnderConstructionPage/UnderConstructionPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<UnderConstructionPage />} />
      {/* Puedes agregar más rutas aquí */}
    </>
  )
);

export default router;
